import { render, staticRenderFns } from "./CreateClassTicket.vue?vue&type=template&id=48f6e88d&scoped=true"
import script from "./CreateClassTicket.vue?vue&type=script&lang=js"
export * from "./CreateClassTicket.vue?vue&type=script&lang=js"
import style0 from "./CreateClassTicket.vue?vue&type=style&index=0&id=48f6e88d&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f6e88d",
  null
  
)

export default component.exports